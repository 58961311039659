import React from 'react';
import { graphql } from 'gatsby';
import {
  AccessibilityAuditComponentResultsPartial,
  ItemsListIndexPage,
  Link,
  PageHero,
  PageNavigation,
  PageWithSubNav,
  Paragraph,
  Section,
} from '../../../components';
import pageHeroData from '../../../data/pages/components.yml';

const IndexPage = ({ data }) => {
  return (
    <PageWithSubNav
      pageType="design"
      title="Type Overview"
      designCode
      subnav="components">
      <PageHero heroData={pageHeroData} tierTwo="Type" tierThree="Overview" />

      <PageNavigation links={['Components', 'Accessibility']} />

      <Section title="Components">
        <Paragraph>
          We need type to display words and symbols at the optimal level in the
          visual hierarchy. These components make that easy.
        </Paragraph>
        <ItemsListIndexPage
          data={data}
          tierTwo="type"
          listType="visual"
          listLayout="3"
        />
      </Section>

      <Section title="Accessibility">
        <Paragraph>
          Check out our{' '}
          <Link href="/guidelines/accessibility/text/design">
            accessibility guidelines for text
          </Link>{' '}
          to learn how we strive to make text accessible to all users.
        </Paragraph>
        <AccessibilityAuditComponentResultsPartial componentName="Type" />
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;

export const query = graphql`
  query typeDesignOverviewQuery($tierOne: String = "Components") {
    ...indexList
  }
`;
